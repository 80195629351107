import { motion } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";
import UserIcon from "../../user.png"

export default function Carousel({ heading }) {
  const [view, setView] = useState(0);
  const [opacity, setOpacity] = useState([1, 0.3, 0.3, 0.3, 0.3, 0.3]);
  const caroPlate = useRef(null);
  const [width, setWidth] = useState(0);

  let [carouselItems, setCarouselItems] = useState([
    {
      name: "Mr. Shubam Prajapathi",
      description: "Innovative Scripts Pvt Ltd",
      testimonial:
        "Working with Zorway for our client's website was a game-changer .Zorway's proficiency in web development and commitment to comprehending the objectives of our client were exceptional. Our client was delighted with the outcome of the website they produced, which exceeded expectations.",
      image:UserIcon,
    },
    {
      name: "Mr. Nitesh Deshmukh",
      description: "Brainlytic Solutions Private Limited",
      testimonial:
        "Zorway exceeded expectations! Their user-friendly app, crafted by a skilled development team, impressed us with its quality service, understanding of business requirements, and cost-effectiveness. We wholeheartedly recommend Zorway as a reputable, knowledgeable digital solutions provider.",
      image:UserIcon
        },
    {
      name: "Mr. Swamy",
      description: "Hanuman Travels",
      testimonial:
        "Zorway's quick launch of our website and seamless Google Ads integration was outstanding! Their efficiency and expertise ensured a rapid setup that perfectly complemented our digital marketing strategy. We highly recommend Zorway for exceptional service and skillful advertising solutions integration.",
      image:UserIcon},
    {
      name: "Mr. Dipanker Nayek",
      description: "JLDSSS",
      testimonial:
        "Zorway created top-quality, affordable Flutter applications for our food delivery business. The designs are great, and the applications run smoothly. Their work exceeded our expectations, and we praise Zorway for their exceptional service and app development skills..",
      image:UserIcon},

  ]);


  useEffect(() => {
    setWidth(Number(caroPlate.current.getBoundingClientRect().width) + 70);
    const interval = setInterval(() => {
      setOpacity((prevOpacity) => {
        let temp = [...prevOpacity];
        temp[view] = 0.3;
        setView((view + 1)%6);
        temp[(view + 1)%6] = 1;
        return temp;
      });
    }, 3000);
    return () => clearInterval(interval);
  }, [view]);

  return (
    <>
      <div class="title-white testimonial-heading" style={{ textAlign: "center" }}>
        <span class="purple-head">Our </span>Testimonials
      </div>

      <div
        className="caro-parent"
        style={{
          width: "100%",
          height: "max-content",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        <motion.div
          className="caro-container"
          animate={
            window.innerWidth <= 1023
              ? { x: (width + 35) * view * -1 + (view - 1) * 35 + 30 }
              : { x: width * view * -1 + (width + 70) / 2 }
          }
          transition={{ duration: 1 }}
        >
          {carouselItems.map((item, index) => (
            <motion.div key={index} animate={{ opacity: opacity[index] }} className="caro-plates" ref={caroPlate}>
              <div className="caro-plate-1">
                <div className="caro-info-container">
                  <img className="caro-avatar"  src={item.image} alt="" />
                  <div className="caro-plate-1-info">
                    <span className="member-name">{item.name}</span>
                    <span className="member-description">{item.description}</span>
                  </div>
                </div>
              </div>
              <div className="caro-plate-2 caro-color-main">
                <span className="caro-color-special">{item.testimonial}</span>
              </div>
            </motion.div>
          ))}
        </motion.div>
        <div
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "122px",
            fontSize: "2.25rem",
            gap: "4rem",
            cursor: "pointer",
          }}
        >
          <i
            class="fa-solid fa-arrow-left"
            onClick={() => {
              if (view > 0) {
                setOpacity((prevOpacity) => {
                  let temp = [...prevOpacity];
                  temp[view] = 0.3;
                  temp[(view - 1) % 6] = 1;
                  return temp;
                });
                setView((view - 1) % 6);
              }
            }}
          ></i>
          <i
            class="fa-solid fa-arrow-right"
            onClick={() => {
              if (view < carouselItems.length - 1) {
                setOpacity((prevOpacity) => {
                  let temp = [...prevOpacity];
                  temp[view] = 0.3;
                  temp[(view + 1) % 6] = 1;
                  return temp;
                });
                setView((view + 1) % 6);
              }
            }}
          ></i>
        </div>
      </div>
    </>
  );
}

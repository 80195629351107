import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import Glossy from "../General/Glossy";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { testimonials } from './testData';

export default function View() {
  const sectionRef = useRef(null);
  const controls = useAnimation();
  const containerAnimation = useAnimation();
  const { pathname } = useLocation();

  const [testimonial, setTestimonial] = useState({ title: "", content: "" });

  let navigate = useNavigate();

  useEffect(() => {
    let arr = pathname.split("/");
    let id = arr[arr.length - 1];
    const selectedTestimonial = testimonials.find(
      (item) => item.id == id
    );
    if (selectedTestimonial) {
      setTestimonial(selectedTestimonial);
    } else {
      navigate("/testimonial");
    }
  }, [pathname, navigate]);

  useEffect(() => {
    // Set window position to 0 with smooth scroll animation
    const scrollOptions = {
      top: 0,
      behavior: 'smooth'
    };

    const smoothScrollToTop = () => {
      const scrollToTop = () => {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

        if (currentScroll > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, currentScroll - currentScroll / 8);
        }
      };

      scrollToTop();
    };

    smoothScrollToTop();
    containerAnimation.start({
      opacity: 1,
      x: 0,
      transition: { duration: 0.5, ease: "easeOut" },
    });
  }, [containerAnimation]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "linear", duration: 1 }}
        className="tools story tools-header"
      >
        <div>
          <div className="button-tools icon-btn"
            onClick={() => { navigate(-1) }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M14.1199 5.56763C14.251 5.69828 14.355 5.85351 14.426 6.02445C14.497 6.19538 14.5335 6.37864 14.5335 6.56372C14.5335 6.74881 14.497 6.93207 14.426 7.103C14.355 7.27393 14.251 7.42917 14.1199 7.55982L8.08594 13.5938H25.3125C25.6855 13.5938 26.0431 13.742 26.3069 14.0057C26.5706 14.2694 26.7187 14.6271 26.7187 15.0001C26.7187 15.373 26.5706 15.7307 26.3069 15.9944C26.0431 16.2581 25.6855 16.4063 25.3125 16.4063H8.08594L14.1199 22.4426C14.3841 22.7068 14.5325 23.0651 14.5325 23.4387C14.5325 23.8123 14.3841 24.1706 14.1199 24.4348C13.8557 24.699 13.4974 24.8474 13.1238 24.8474C12.7502 24.8474 12.3919 24.699 12.1277 24.4348L3.69024 15.9973C3.55914 15.8667 3.45511 15.7114 3.38414 15.5405C3.31316 15.3696 3.27663 15.1863 3.27663 15.0012C3.27663 14.8161 3.31316 14.6329 3.38414 14.4619C3.45511 14.291 3.55914 14.1358 3.69024 14.0051L12.1277 5.56763C12.2584 5.43653 12.4136 5.33251 12.5846 5.26153C12.7555 5.19056 12.9387 5.15402 13.1238 5.15402C13.3089 5.15402 13.4922 5.19056 13.6631 5.26153C13.834 5.33251 13.9893 5.43653 14.1199 5.56763Z"
                fill="white"
                fill-opacity="0.8"
              />
            </svg>

          </div>
        </div>
      </motion.div>

      <motion.div className="thumb-parent story tp-reader testimonial-a" >
        <div className="about-heading testimonial-c">
          <span className="about-heading-grad">{testimonial.head}</span>
        </div>
        <div class="image-container" >
          <div className="image-overlay testimonial-a"></div>
          <motion.img className="testimonial-a"
            style={{ maxHeight: "607px", maxWidth: "100%" }}
            src={testimonial.imageBig}
            alt=""
          />
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "linear", duration: 1 }}
        style={{ paddingInline: "7vw" }}
        className="read-container testimonial-d"
      >
        <div className="title-white testimonial-f">
          {testimonial.head}
        </div>
        <div className="content-blog testimonial-e">
          {testimonial.data}
        </div>
      </motion.div>
      <br />
      <div class="title-white" style={{ textAlign: "center" }}><span class="purple-head">The </span>Results</div>
      <br />
      <Glossy bg={`url("112.png")`} position={"right"} paddingInline={false}>
        <div className="stats-container">
          <div className="stats-sub-container">
            <div className="stats-item-a">1+</div>
            <div className="stats-item-b">Years of Business</div>
          </div>
          <div className="stats-sub-container">
            <div className="stats-item-a">15+</div>
            <div className="stats-item-b">Happy Clients</div>
          </div>
          <div className="stats-sub-container">
            <div className="stats-item-a">20+</div>
            <div className="stats-item-b">Projects Completed</div>
          </div>
          <div className="stats-sub-container">
            <div className="stats-item-a">15k+</div>
            <div className="stats-item-b">Lines of Code</div>
          </div>
        </div>
      </Glossy>
      <br />
    </>

  );
}

export const testimonials = [
  {
    id: 1,
    title: 'Revolutionizing Cubing Competitions',
    subtitle: 'Empowering Cubing Enthusiasts and Redefining Online Competitions',
    content: <>Our client  “Brainlytic Solutions Pvt. Ltd. (Cubelelo)” asked us to create an online events website where cubers could participate and win prizes. <span className="testimonial-color-white"> We took this challenge and made such a portal which they loved!</span>
    </>,
    link: '/story/1',
    imageUrl: '/imgtest/test1.jpg',
    head: <>Revolutionizing <br /> Cubing <br /> Competitions</>,
    imageBig: '/imgtest/bigtest1.png',
    data: <>
      <div className="content-blog-heading">
        Empowering Cubing Enthusiasts and Redefining Online Competitions
      </div>
      <br />
      Our client  “Brainlytic Solutions Pvt. Ltd. (Cubelelo)” asked us to create an online events website where cubers could participate and win prizes. We took this challenge and made such a portal which they loved!
      <br />
      <br />
      In the fast-paced world of web development, our team took on an exciting journey to create a cutting-edge events portal for our esteemed client. This portal isn't just any ordinary platform; it's a digital haven for cubing enthusiasts, offering a unique and immersive competition experience right at their fingertips.
      <br />
      <br />
      This Events Portal is a game-changer, providing a seamless digital environment where users can participate in thrilling cubing events and stand a chance to win exciting prizes. What sets this portal apart is its comprehensive range of features designed to enhance user engagement and competition dynamics.
      <br />
      <br />
      From user account creation and result storage to timer functionality, personal statistics tracking, and the ability to check other participants' stats, this portal offers a complete suite of tools for an unparalleled cubing experience. Moreover, the admin portal ensures smooth management of all aspects, guaranteeing a hassle-free and enjoyable journey for both users and administrators alike.
      <br />
      <br />
      <img src="/imgtest/test1a.png" style={{ maxWidth: "100%" }} />
      <br /><br />
      With this innovative Events Portal, we're not just creating a platform; we're fostering a community of passionate cubers, revolutionizing the way online competitions are conducted, and setting new standards in digital event management. Thus providing immense benefits to our esteemed Client. The portal can be viewed at <a href="https://events.cubelelo.com">https://events.cubelelo.com</a><br />
      Join us on this exhilarating journey as we redefine the future of digital competitions.
      <img src="/imgtest/test1b.png" style={{ maxWidth: "100%" }} />

    </>,
  },
  {
    id: 2,
    title: "Transforming Rubik's Cube Solving with Our Innovative Online Gaming Platform",
    subtitle: 'Empowering Learning Through Interactive Gaming',
    content: <>   At Zorway, we're not just about creating websites; we're about revolutionizing learning experiences. Satisfied with our past work, our client <span className="testimonial-color-white">Brainlytic Solutions Pvt. Ltd. (Cubelelo) approached us again </span> and asked us to develop an online gaming console which could change the way people learn and practice solving Rubik's Cube.</>,
    link: '/story/2',
    imageUrl: '/imgtest/test2.jpg',
    head: <>Transforming <br /> Rubik's Cube <br /> Solving with Our <br /> Innovative Online <br /> Gaming Platform</>,
    imageBig: '/imgtest/bigtest2.png',
    data: <>
      <div className="content-blog-heading">
        Empowering Learning Through Interactive Gaming
      </div>
      <br />

      At Zorway, we're not just about creating websites; we're about revolutionizing learning experiences. Satisfied with our past work, our client Brainlytic Solutions Pvt. Ltd. (Cubelelo) approached us again and asked us to develop an online gaming console which could change the way people learn and practice solving Rubik's Cube.
      <img src="/imgtest/test2a.png" style={{ maxWidth: "100%" }} />
      <br /><br />
      This platform is more than just a digital space; it's a vibrant and interactive learning environment designed to engage users of all ages and skill levels. Whether you're a beginner or an experienced cuber, this platform offers a seamless journey of learning and improvement.
      <br /><br />
      Users can register, access comprehensive lessons, and dive into the fun of solving Rubik's Cube virtually. The interactive gameplay makes learning enjoyable and engaging, ensuring that users stay motivated and committed to mastering the cube.

      <br /><br />
      What sets this platform apart is its blend of education and entertainment. We understand that learning should be fun, and this gaming console delivers just that. It's not just about solving a puzzle; it's about unlocking new skills, boosting confidence, and fostering a love for learning. The platform could be viewed at:<a href="https://upcube.io/"> https://upcube.io/</a><br />

      <br />
      For businesses looking to enhance their digital offerings and provide innovative learning solutions, Zorway is your trusted partner. Join us in revolutionizing education through interactive gaming experiences that inspire, engage, and empower learners worldwide.
      <img src="/imgtest/test2b.png" style={{ maxWidth: "100%" }} />

    </>,
  },
  {
    id: 3,
    title: 'Clinic Khojo: Revolutionizing Healthcare Access',
    subtitle: 'Empowering Patients and Doctors Alike for a Seamless Healthcare Experience',
    content: <> Our client “Shamyani Health Services Pvt Ltd” found themselves grappling with a common challenge - long queues of patients waiting for doctor consultations. This not only caused frustration but also hindered the efficiency of the clinic. Together, <span className="testimonial-color-white">we developed "Clinic Khojo", a game-changing app designed to streamline the appointment process and enhance the overall patient experience.</span></>,
    link: '/story/3',
    imageUrl: '/imgtest/test3.jpg',
    head: <>Clinic Khojo: <br /> Revolutionizing <br /> Healthcare <br /> Access</>,
    imageBig: '/imgtest/bigtest3.jpg',
    data: <>
      <div className="content-blog-heading">Empowering Patients and Doctors Alike for a Seamless Healthcare Experience</div>
      <br />
      In the bustling world of healthcare, Our client “Shamyani Health Services Pvt Ltd” found themselves grappling with a common challenge - long queues of patients waiting for doctor consultations. This not only caused frustration but also hindered the efficiency of the clinic. Patients were tired of spending hours in waiting rooms, and doctors were overwhelmed with managing appointments and patient data manually. Something had to change.
      <br /><br />
      They approached us, with a mission to revolutionize healthcare through innovative technology. Together, we developed "Clinic Khojo", a game-changing app designed to streamline the appointment process and enhance the overall patient experience.
      <br /><br />
      Picture this: a patient-friendly loading page adorned with the business logo, signaling the start of a digital healthcare revolution. Patients are greeted with a seamless login process using OTP, eliminating the hassle of remembering passwords. They can then explore a wide array of doctors categorized by expertise, location, and even specific symptoms. With just a few taps, appointments can be booked, emergencies can be addressed promptly, and payments can be made securely.

      <br /><br />
      For doctors and clinic staff, "Clinic Khojo" offers a comprehensive suite of tools. They can manage their schedules, track appointments, access patient details on the go, and even access to statistics to improve their services. The app empowers healthcare providers to deliver personalized care and streamline their administrative tasks, leading to better patient outcomes and operational efficiency.
      <img src="/imgtest/test3a.png" style={{ maxWidth: "100%" }} />

      <br /><br />
      Through "Clinic Khojo," we've demonstrated the power of technology to humanize healthcare. It's about more than just booking appointments; it's about fostering trust, convenience, and compassion in every interaction. Zorway is committed to empowering businesses across industries with innovative solutions that make a real difference in people's lives. Join us in shaping a future where technology meets empathy, creating a world of healthier and happier communities.
      <img src="/imgtest/test3b.png" style={{ maxWidth: "100%" }} />

    </>,
  },
];
import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import Glossy from "../General/Glossy";
import { useScroll } from "framer-motion";
import Marquee from "react-fast-marquee";
import Carousel from "../General/Carousel";
import { Link } from "react-router-dom"
import Counter from "../General/Counter";
import { testimonials } from './testData';

export default function Testimonials() {
  const sectionRef = useRef(null);
  const controls = useAnimation();
  const containerAnimation = useAnimation();

  useEffect(() => {
    // Set window position to 0 with smooth scroll animation
    const scrollOptions = {
      top: 0,
      behavior: 'smooth'
    };

    const smoothScrollToTop = () => {
      const scrollToTop = () => {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

        if (currentScroll > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, currentScroll - currentScroll / 8);
        }
      };

      scrollToTop();
    };

    smoothScrollToTop();
    containerAnimation.start({
      opacity: 1,
      x: 0,
      transition: { duration: 0.5, ease: "easeOut" },
    });
  }, [containerAnimation]);
  const b = useScroll();

  return (
    <>
      <motion.section
        initial={{ y: 300, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ type: "spring", damping: 8, duration: 0.5 }}
        className="about-hero-section testimonial"
        style={{ justifyContent: "flex-start", paddingTop: "9rem" }}
      >
        <motion.div
          style={{
            position: "relative",
            zIndex: 98,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div class="about-heading">
            <span class="about-heading-grad">Collaborate</span>
            <br />
            <span> and Conquer.</span>
          </div>
          <div className="subtitle maxw-sub">
            Unleash the full potential of your concept, enterprise, and
            aspirations, turning them into reality.
          </div>
        </motion.div>
        <motion.div
          className="classic-1 avatars-bg animate__animated animate__pulse animate__infinite animate__slower"
          initial={{ y: "-100px", opacity: 0 }}
          animate={{ y: "0px", opacity: 1 }}
          transition={{ duration: 1.8, ease: [0.42, 0, 0.58, 1] }}
          src=""
          alt=""
        />
        <motion.div
          className="classic-1 glows-bg"
          initial={{ y: "-100px", opacity: 0 }}
          animate={{ y: "0px", opacity: 1 }}
          transition={{ duration: 1.8, ease: [0.42, 0, 0.58, 1] }}
          src=""
          alt=""
        />
        <motion.img
          initial={{ x: "100px", opacity: 0 }}
          animate={{ x: "0px", opacity: 1 }}
          transition={{ duration: 1, ease: [0.42, 0, 0.58, 1] }}
          className="classic-2"
          src="Group 1000001776.svg"
          alt=""
        />
      </motion.section>

      <div className="container-sixth" style={{ paddingBlock: "4rem" }}>
        <Marquee style={{ width: "80vw" }}>
          <div className="marquee-child">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_671)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.83545 0.835449C11.9775 0.835449 15.3354 4.19331 15.3354 8.33545V0.835449H22.8354C26.9775 0.835449 30.3354 4.19331 30.3354 8.33545C30.3354 12.4775 26.9775 15.8354 22.8354 15.8354C26.9775 15.8354 30.3354 19.1933 30.3354 23.3354C30.3354 25.3922 29.5075 27.2556 28.1668 28.6105L28.1388 28.6388L28.1139 28.6635C26.7586 30.0062 24.8939 30.8354 22.8354 30.8354C20.791 30.8354 18.9376 30.0174 17.5847 28.6908C17.5672 28.6736 17.5496 28.6562 17.5321 28.6388C17.5157 28.6223 17.4993 28.6059 17.4831 28.5893C16.1547 27.2361 15.3354 25.3814 15.3354 23.3354C15.3354 27.4775 11.9775 30.8354 7.83545 30.8354C3.69331 30.8354 0.335449 27.4775 0.335449 23.3354V15.8354H7.83545C3.69331 15.8354 0.335449 12.4775 0.335449 8.33545C0.335449 4.19331 3.69331 0.835449 7.83545 0.835449ZM13.8354 8.33545C13.8354 11.6492 11.1492 14.3354 7.83545 14.3354V2.33545C11.1492 2.33545 13.8354 5.02174 13.8354 8.33545ZM28.8354 23.3354C28.8354 20.0217 26.1492 17.3354 22.8354 17.3354C19.5217 17.3354 16.8354 20.0217 16.8354 23.3354H28.8354ZM1.83545 17.3354V23.3354C1.83545 26.6492 4.52174 29.3354 7.83545 29.3354C11.1492 29.3354 13.8354 26.6492 13.8354 23.3354V17.3354H1.83545ZM16.8354 14.3354V2.33545H22.8354C26.1492 2.33545 28.8354 5.02174 28.8354 8.33545C28.8354 11.6492 26.1492 14.3354 22.8354 14.3354H16.8354Z"
                  fill="#E9E9E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_671">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(0.335449 0.835449)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </Marquee>
      </div>

      <Counter></Counter>

      <div
        style={{
          paddingBlock: "16.9375rem 6.125rem",
          textAlign: "center",
          paddingInline: "1.875rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: "54.8125rem" }}>
          <div className="title-white purple-head">
            Better workflow. No matter
          </div>
          <div className="title-white">what the work is.</div>
          <div className="subtitle">
            Accelerated post-production processes. Streamlined review cycles.
            Enhanced collaboration. Content creators across various domains
            utilize Zorway to elevate their work to new heights.
          </div>
        </div>
      </div>

      {testimonials.map((testimonial) => (
        <div className={`article-container ${testimonial.id % 2 === 0 ? 'blackie' : ''}`} key={testimonial.id}>
          {testimonial.id % 2 === 0 ? (
            <>
              {/* Render the content first */}
              <div className="article-subcontainer">
                <div className="article-subcontainer-sub">
                  <div>
                    <div>
                      <span className="title-white purple-head">
                        {testimonial.title}
                      </span>
                    </div>
                    <div className="subtitle">
                      {testimonial.subtitle}
                    </div>
                  </div>
                  <div className="testimonial-typo">

                    {testimonial.content}

                  </div>
                  <a href={testimonial.link}>
                    <div className="outlined-btn" style={{ margin: "unset" }}>
                      <div>Their Story</div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          d="M24.5138 14.0053C24.513 13.8326 24.4773 13.6618 24.4087 13.5033C24.3401 13.3448 24.2402 13.2018 24.1148 13.083L16.8173 5.78552C16.5268 5.50727 16.2276 5.38477 15.9161 5.38477C15.2056 5.38477 14.6946 5.88527 14.6946 6.56252C14.6946 6.91777 14.8381 7.21877 15.0621 7.44102L17.5593 9.97327L20.7811 12.9168L18.2051 12.761H4.74059C3.99684 12.761 3.48584 13.272 3.48584 14.0053C3.48584 14.728 3.99684 15.239 4.74059 15.239H18.2051L20.7811 15.0815L17.5611 18.0268L15.0603 20.559C14.9435 20.6734 14.8507 20.8101 14.7876 20.961C14.7245 21.1119 14.6923 21.274 14.6928 21.4375C14.6928 22.1148 15.2056 22.6153 15.9161 22.6153C16.2276 22.6153 16.5286 22.4928 16.7928 22.2373L24.1148 14.917C24.2389 14.7998 24.3382 14.6588 24.4067 14.5025C24.4753 14.3462 24.5117 14.1759 24.5138 14.0053Z"
                          fill="#AA67FE"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
              {/* Render the image next */}
              <img
                className="article-image"
                src={testimonial.imageUrl}
                alt=""
              />
            </>
          ) : (
            <>
              {/* Render the image first */}
              <img
                className="article-image"
                src={testimonial.imageUrl}
                alt=""
              />
              {/* Render the content next */}
              <div className="article-subcontainer">
                <div className="article-subcontainer-sub">
                  <div>
                    <div>
                      <span className="title-white purple-head">
                        {testimonial.title}
                      </span>
                    </div>
                    <div className="subtitle">
                      {testimonial.subtitle}
                    </div>
                  </div>
                  <div className="testimonial-typo">
                    {testimonial.content}
                  </div>
                  <a href={testimonial.link}>
                    <div className="outlined-btn" style={{ margin: "unset" }}>
                      <div>Their Story</div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          d="M24.5138 14.0053C24.513 13.8326 24.4773 13.6618 24.4087 13.5033C24.3401 13.3448 24.2402 13.2018 24.1148 13.083L16.8173 5.78552C16.5268 5.50727 16.2276 5.38477 15.9161 5.38477C15.2056 5.38477 14.6946 5.88527 14.6946 6.56252C14.6946 6.91777 14.8381 7.21877 15.0621 7.44102L17.5593 9.97327L20.7811 12.9168L18.2051 12.761H4.74059C3.99684 12.761 3.48584 13.272 3.48584 14.0053C3.48584 14.728 3.99684 15.239 4.74059 15.239H18.2051L20.7811 15.0815L17.5611 18.0268L15.0603 20.559C14.9435 20.6734 14.8507 20.8101 14.7876 20.961C14.7245 21.1119 14.6923 21.274 14.6928 21.4375C14.6928 22.1148 15.2056 22.6153 15.9161 22.6153C16.2276 22.6153 16.5286 22.4928 16.7928 22.2373L24.1148 14.917C24.2389 14.7998 24.3382 14.6588 24.4067 14.5025C24.4753 14.3462 24.5117 14.1759 24.5138 14.0053Z"
                          fill="#AA67FE"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      ))}

      <br />
      <Carousel heading={false}></Carousel>
    </>
  );
}
